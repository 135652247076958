import { render, staticRenderFns } from "./whitelabel-settings.vue?vue&type=template&id=57f46e85&scoped=true"
import script from "./whitelabel-settings.vue?vue&type=script&lang=js"
export * from "./whitelabel-settings.vue?vue&type=script&lang=js"
import style0 from "./whitelabel-settings.vue?vue&type=style&index=0&id=57f46e85&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f46e85",
  null
  
)

export default component.exports